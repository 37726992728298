<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="充值记录" />
    <van-list v-model="loading" :finished="finish" @load="handleGetIncomeLogList" loading-text="加载中..." finished-text="" error-text="加载失败">
      <div v-if="finish && !loading && recordList.length == 0" class="empty-item">
        <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
      </div>
      <div v-else class="record-list">
        <div class="record-item" v-for="(item, index) in recordList" :key="index">
          <div class="detail">
            <div class="content">{{ item.Content }}</div>
            <div class="money">+{{ Math.round(item.Money).toFixed(2) }}</div>
          </div>
          <div class="detail">
            <div class="time">{{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
            <div class="status">已发放</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "RechargeRecord",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      loading: false,
      finish: false,
      recordList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    calcNewMoney(money, oldMoney) {
      return (parseFloat(oldMoney) + parseFloat(money)).toFixed(10)
    }
  },
  mounted() {

  },
  methods: {
    async handleGetIncomeLogList() {
      const { PageCount, Page, recordList } = this
      const params = {
        PageCount,
        Page,
        Type: 1
      }
      var res = await this.$API.incomeLogList.post(params)
      this.recordList = [
        ...recordList,
        ...res.info
      ]
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  overflow: hidden;
}
.record-list {
  margin: .32rem;
  background-color: #fff;
  border-radius: .16rem;
  padding: 0 .24rem;
  .record-item {
    padding: .24rem 0;
    border-bottom: .02rem solid #f8f8f8;
    &:nth-last-child(1) {
      border-bottom: 0;
    }
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .02rem 0;
      .content {
        line-height: .4rem;
        font-size: .28rem;
        color: #242941;
      }
      .money {
        line-height: .4rem;
        font-size: .28rem;
        color: #242941;
        font-weight: bold;
      }
      .time {
        font-size: .24rem;
        color: #ccc;
      }
      .status {
        width: .98rem;
        height: .4rem;
        border-radius: .08rem;
        background-color: rgba(19, 153, 255, .12);
        color: #276eff;
        font-size: .24rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  //      
}
</style>